import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { LocaleEnum } from '../enums/locale.enum';
import { LocaleStateActions } from './locale.actoins';

export interface ILocaleState {
  language: LocaleEnum | null;
}

const INITIAL_LOCALE_STATE: ILocaleState = {
  language: null,
};

@State<ILocaleState>({
  name: 'locale',
  defaults: INITIAL_LOCALE_STATE,
})
@Injectable()
export class LocaleState {
  @Selector()
  public static getLocale(state: ILocaleState): ILocaleState['language'] {
    return state.language;
  }

  @Action(LocaleStateActions.SetLocale)
  public setLocale({ patchState }: StateContext<ILocaleState>, { locale }: LocaleStateActions.SetLocale): void {
    patchState({ language: locale });
  }
}
