import { SidenavItem } from './sidenav-item.model';

export const SIDENAV_MENU_ITEMS: SidenavItem[] = [
  new SidenavItem({
    icon: 'home',
    name: 'MENU.HOME',
    route: '/',
    routerLinkActiveOptions: { exact: true },
    position: 0,
  }),
  new SidenavItem({
    icon: 'person',
    name: 'MENU.CLIENTS',
    route: '/customers',
    position: 0,
  }),
  new SidenavItem({
    icon: 'directions_car',
    name: 'MENU.FLEET',
    route: '/vehicles',
    position: 0,
  }),
  new SidenavItem({
    icon: 'settings',
    name: 'MENU.CONFIGURATOR',
    route: '/configurator',
    position: 0,
  }),
  new SidenavItem({
    icon: 'content_copy',
    name: 'MENU.RENTS',
    route: '/rents',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'documents-done',
    name: 'MENU.REQUESTS',
    route: '/vehicle-inspections',
    position: 0,
  }),
  new SidenavItem({
    icon: 'grid_view',
    name: 'MENU.TARIFFS',
    route: '/tariffs',
    position: 0,
  }),
  new SidenavItem({
    icon: 'payments',
    name: 'MENU.ORDERS',
    route: '/orders',
    position: 0,
  }),
  new SidenavItem({
    icon: 'done',
    name: 'MENU.CHECKLISTS',
    route: '/check-lists',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'truck-delivery',
    name: 'MENU.DELIVERY_POINTS',
    route: '/delivery-points',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'help-circle',
    name: 'MENU.HELP',
    route: '/faq',
    position: 0,
  }),
  new SidenavItem({
    icon: 'settings',
    name: 'MENU.SETTINGS',
    route: '/settings',
    position: 0,
    disabled: true,
  }),
];
