import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs';

import { LocaleEnum } from './core/locale/enums/locale.enum';
import { LocaleStateActions } from './core/locale/services/locale.actoins';
import { LocaleService } from './core/locale/services/locale.service';
import { DestroyService } from './shared/utils/destroy.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
    private store: Store,
    private destroy$: DestroyService,
  ) {
    this.translate.setDefaultLang(LocaleEnum.En);

    this.localeService.locale$.pipe(takeUntil(this.destroy$)).subscribe((locale) => {
      if (!locale) {
        this.store.dispatch(
          new LocaleStateActions.SetLocale((translate.getBrowserLang() as LocaleEnum) || LocaleEnum.En),
        );
        return;
      }

      this.translate.use(locale);
    });
  }
}
