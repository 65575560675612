import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LocaleEnum } from '../enums/locale.enum';
import { LocaleState } from './locale.state';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  @Select(LocaleState.getLocale) locale$!: Observable<LocaleEnum | null>;

  constructor(private store: Store, private translate: TranslateService) {
    this.translate.addLangs([LocaleEnum.En, LocaleEnum.Ru]);
  }

  public getCurrentLocale(): LocaleEnum {
    return this.store.selectSnapshot(LocaleState.getLocale) || LocaleEnum.En;
  }
}
